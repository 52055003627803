import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";

export const PROFIT_CENTER = Object.freeze({
  FETCH_STARTED: "PROFIT_CENTER_FETCH_STARTED",
  FETCH_ERROR: "PROFIT_CENTER_FETCH_ERROR",
  FETCH_FINISHED: "PROFIT_CENTER_FETCH_FINISHED",
});

// -- ACTIONS --
export const fetchProfitCenters =
  // eslint-disable-next-line consistent-return
  profitCenterIds => async (dispatch, getState) => {
    try {
      // start
      dispatch({ type: PROFIT_CENTER.FETCH_STARTED });

      const origoBaseUrl = ensureTrailingSlash(process.env.REACT_APP_ORIGO_URL);

      console.time("fetchProfitCenter");
      const calls = profitCenterIds.map(profitCenterId => {
        const path = `${origoBaseUrl}api/v1/profit-center/${profitCenterId}`;
        const { tokenData } = getState().user;
        const config = RestService.createConfig(tokenData.accessToken);
        return RestService.get(path, config);
      });

      const { profitCenterMap } = getState().profitCenter;
      const profitCenters = await Promise.all(calls);
      const updatedProfitCenterMap = profitCenters.reduce((acc, next) => {
        acc.set(next.number, next.name);
        return acc;
      }, profitCenterMap);
      console.timeEnd("fetchProfitCenter");

      // update
      dispatch({
        type: PROFIT_CENTER.FETCH_FINISHED,
        payload: updatedProfitCenterMap,
      });

      return updatedProfitCenterMap;
    } catch (error) {
      dispatch({ type: PROFIT_CENTER.FETCH_ERROR, payload: error });
    }
  };

// -- REDUCER --

const INIT_STATE = {
  profitCenterMap: new Map(),
  profitCenterFetchError: null,
  fetchingProfitCenter: false,
};

// eslint-disable-next-line default-param-last
export const profitCenterReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROFIT_CENTER.FETCH_STARTED:
      return {
        ...state,
        fetchingProfitCenter: true,
        profitCenterFetchError: null,
      };
    case PROFIT_CENTER.FETCH_FINISHED:
      return {
        ...state,
        fetchingProfitCenter: false,
        profitCenterMap: action.payload,
      };
    case PROFIT_CENTER.FETCH_ERROR:
      return {
        ...state,
        fetchingProfitCenter: false,
        profitCenterFetchError: action.payload,
      };
    default:
      return state;
  }
};
