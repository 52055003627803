/* eslint-disable @typescript-eslint/no-unused-expressions */
const shouldLog = action => {
  // enable this if logging is wanted
  const loggerEnabled = true;

  // filter
  let logByFilter = false;
  if (action.type != null) {
    logByFilter = action.type.includes("@@router");
  }

  // return only loggerEnabled = true if all logging is wanted
  return loggerEnabled && logByFilter;
};

const logger = store => next => action => {
  const log = shouldLog(action);
  log && console.group(action.type);
  log && console.info("dispatching", action);
  const result = next(action);
  log && console.log("next state", store.getState());
  log && console.groupEnd();
  return result;
};

export default logger;
