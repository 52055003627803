// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { Button, OriolaColors } from "@oriola-origo/origo-ui-core";
import { Box, Snackbar, Typography } from "@material-ui/core";
import {
  AlertErrorCircle,
  AlertInfoCircle,
  AlertWarningCircle,
  AlertSuccessCircle,
  Close,
} from "../../images";

const Variant = Object.freeze({
  Success: "success",
  Error: "error",
  Info: "info",
  Warning: "warning",
});

function Text({ children, color, variant, noWrap, ...rest }) {
  return (
    <Box {...rest} color={color || OriolaColors.NavyBlue}>
      <Typography variant={variant} noWrap={noWrap}>
        {children}
      </Typography>
    </Box>
  );
}

Text.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  variant: PropTypes.string,
  noWrap: PropTypes.bool,
};

Text.defaultProps = {
  children: null,
  color: null,
  variant: null,
  noWrap: false,
};

function Toast({
  open,
  onClose,
  autoHideDuration,
  variant,
  text,
  icon,
  ...rest
}) {
  const getVariantColor = (value = Variant.Success) => {
    switch (value) {
      case Variant.Warning:
        return OriolaColors.Orange;
      case Variant.Info:
        return OriolaColors.Blue;
      case Variant.Error:
        return OriolaColors.Red;
      case Variant.Success:
      default:
        return OriolaColors.Green;
    }
  };

  const getVariantIcon = (value = Variant.Success) => {
    switch (value) {
      case Variant.Warning:
        return <AlertWarningCircle />;
      case Variant.Info:
        return <AlertInfoCircle />;
      case Variant.Error:
        return <AlertErrorCircle />;
      case Variant.Success:
      default:
        return <AlertSuccessCircle />;
    }
  };
  return (
    <Snackbar open={open} onClose={onClose} autoHideDuration={autoHideDuration}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        bgcolor={getVariantColor(variant)}
        borderRadius="4px"
        px={2}
        py={1}
        minWidth="300px"
        {...rest}
      >
        {icon || getVariantIcon(variant)}
        <Text mx={1} flexGrow={1} color={OriolaColors.White}>
          {text}
        </Text>
        <Button variant="transparent" onClick={onClose}>
          <Close />
        </Button>
      </Box>
    </Snackbar>
  );
}

Toast.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  variant: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.node,
};

Toast.defaultProps = {
  open: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  autoHideDuration: null,
  variant: "",
  text: "",
  icon: null,
};

export default Toast;
