import { LOCATION_CHANGE } from "connected-react-router";
import { User } from "../redux/reducers/user/user";
import pageViewEvent from "./pageViewEvents";
import { loginUserEvent, logoutUserEvent } from "./authEvents";

const eventsMapper = action => {
  // console.log('GTM: ', action.type);
  switch (action.type) {
    case LOCATION_CHANGE:
      return pageViewEvent;
    case User.USER_SIGN_IN:
      return loginUserEvent;
    case User.USER_SIGN_OUT:
      return logoutUserEvent;
    default:
      return null;
  }
};

export default eventsMapper;
