import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";

export const Customer = Object.freeze({
  FETCH_STARTED: "CUSTOMER_FETCH_STARTED",
  FETCH_FINISHED: "CUSTOMER_FETCH_FINISHED",
  FETCH_ERROR: "CUSTOMER_FETCH_ERROR",
  UPDATE_CUSTOMERS: "CUSTOMER_UPDATE",
  CUSTOMER_SET_SELECTED: "CUSTOMER_SET_SELECTED",
});

const baseUrl = ensureTrailingSlash(
  process.env.REACT_APP_PRODUCT_MANAGEMENT_API
);

const formatCustomerName = customer => {
  if (!customer) {
    return "";
  }

  return [customer.Name1, customer.Name2].filter(x => x).join(", ");
};

const formatDeliveryAddress = addr => {
  if (!addr) {
    return "";
  }
  const streetAddress = [addr.Street, addr.HouseNum1, addr.HouseNum2]
    .filter(x => x)
    .join(" ");
  const postAddress = [addr.PostCode1, addr.City1].filter(x => x).join(" ");
  return [streetAddress, postAddress].join(", ");
};

const transformDeliveryAddress = a => ({
  ...a,
  address: formatDeliveryAddress(a),
});

const transformCustomer = c =>
  // add formatted name and formatted delivery addresses
  ({
    ...c,
    name: formatCustomerName(c),
    deliveryAddresses: (c.deliveryAddresses || []).map(
      transformDeliveryAddress
    ),
  });

const customerComparator = (c1, c2) => {
  if (c1.customerId === c1.statisticalCustomerId) {
    return -1;
  }
  if (c2.customerId === c2.statisticalCustomerId) {
    return +1;
  }
  const name1 = c1.name;
  const name2 = c2.name;
  return name1.localeCompare(name2, undefined, { sensitivity: "accent" });
};

// -- ACTIONS
// eslint-disable-next-line consistent-return
export const fetchCustomers = customerIds => async dispatch => {
  const path = `${baseUrl}customer/v1/?customerIds=${customerIds.join(",")}`;

  try {
    dispatch({
      type: Customer.FETCH_STARTED,
    });

    console.time("fetchCustomers");
    const customers = await RestService.get(path);
    console.timeEnd("fetchCustomers");

    dispatch({
      type: Customer.FETCH_FINISHED,
    });

    dispatch({
      type: Customer.UPDATE_CUSTOMERS,
      payload: customers,
    });

    return customers;
  } catch (error) {
    console.log("Customer fetching error", error);
    // TODO: error handling
    dispatch({ type: Customer.FETCH_ERROR, payload: error });
  }
};

export const setSelectedCustomer = customerId => dispatch =>
  dispatch({
    type: Customer.CUSTOMER_SET_SELECTED,
    payload: customerId,
  });

// -- REDUCER --

const DEFAULT_STATE = {
  fetchingCustomers: false,
  customersFetchError: null,
  customers: [],
  selectedCustomerId: null,
};
// eslint-disable-next-line default-param-last
export const customerReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Customer.FETCH_STARTED:
      return { ...state, fetchingCustomers: true, customersFetchError: null };
    case Customer.FETCH_ERROR:
      return {
        ...state,
        fetchingCustomers: false,
        customersFetchError: action.payload,
      };
    case Customer.FETCH_FINISHED:
      return { ...state, fetchingCustomers: false };
    case Customer.UPDATE_CUSTOMERS: {
      const customers = (action.payload || []).map(transformCustomer);
      customers.sort(customerComparator);
      return { ...state, customers };
    }
    case Customer.CUSTOMER_SET_SELECTED:
      return { ...state, selectedCustomerId: action.payload };
    default:
      return state;
  }
};
