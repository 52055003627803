// eslint-disable-next-line no-use-before-define
import React from "react";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import colors from "../theme/colors";

const styles = {
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {},
  imageGrey: {
    filter: `opacity(.5) drop-shadow(0 0 0 ${colors.secondaryDarkGray})`,
  },
  wrench: {
    width: "25px",
    height: "25px",
  },
};

export function Wrench() {
  return (
    <img
      style={styles.wrench}
      alt="wrench"
      src={require("./wrench/wrench.png").default}
      srcSet={`
            ${require("./wrench/wrench@2x.png").default} 2x, 
            ${require("./wrench/wrench@3x.png").default} 3x
          `}
    />
  );
}

export function Calendar() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="calendar"
        src={require("./calendar/calendar.png")}
        srcSet={`
            ${require("./calendar/calendar@2x.png")} 2x, 
            ${require("./calendar/calendar@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Notify() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="save"
        src={require("./notify/notify.png")}
        srcSet={`
            ${require("./notify/notify@2x.png")} 2x, 
            ${require("./notify/notify@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Save() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="save"
        src={require("./save/save.png")}
        srcSet={`
            ${require("./save/save@2x.png")} 2x, 
            ${require("./save/save@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Edit() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="edit"
        src={require("./edit/edit.png")}
        srcSet={`
            ${require("./edit/edit@2x.png")} 2x, 
            ${require("./edit/edit@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function More() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="less"
        src={require("./more/more.png")}
        srcSet={`
            ${require("./more/more@2x.png")} 2x, 
            ${require("./more/more@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Less() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="less"
        src={require("./less/less.png")}
        srcSet={`
            ${require("./less/less@2x.png")} 2x, 
            ${require("./less/less@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Search() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="search"
        src={require("./search/search.png")}
        srcSet={`
            ${require("./search/search@2x.png")} 2x, 
            ${require("./search/search@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function ImportExcelIcon() {
  return <img alt="import-icon" src={require("./import/import.svg").default} />;
}

export function Close({ style }) {
  return (
    <img style={style} alt="close" src={require("./close/close.svg").default} />
  );
}

Close.propTypes = {
  style: PropTypes.shape({}),
};

Close.defaultProps = {
  style: null,
};

export function AlertErrorCircle({ style }) {
  return (
    <img
      style={style}
      alt="alert-error-circle"
      src={require("./alert-error-circle/alert-error-circle.svg").default}
    />
  );
}

AlertErrorCircle.propTypes = {
  style: PropTypes.shape({}),
};

AlertErrorCircle.defaultProps = {
  style: null,
};

export function AlertInfoCircle({ style }) {
  return (
    <img
      style={style}
      alt="alert-info-circle"
      src={require("./alert-info-circle/alert-info-circle.svg").default}
    />
  );
}

AlertInfoCircle.propTypes = {
  style: PropTypes.shape({}),
};

AlertInfoCircle.defaultProps = {
  style: null,
};

export function AlertSuccessCircle({ style }) {
  return (
    <img
      style={style}
      alt="alert-success-circle"
      src={require("./alert-ok-circle/alert-ok-circle.svg").default}
    />
  );
}

AlertSuccessCircle.propTypes = {
  style: PropTypes.shape({}),
};

AlertSuccessCircle.defaultProps = {
  style: null,
};

export function AlertWarningCircle({ style }) {
  return (
    <img
      style={style}
      alt="alert-warning-circle"
      src={require("./alert-warning-circle/alert-warning-circle.svg").default}
    />
  );
}

AlertWarningCircle.propTypes = {
  style: PropTypes.shape({}),
};

AlertWarningCircle.defaultProps = {
  style: null,
};

export function NotiOn() {
  return (
    <img
      style={styles.icon}
      alt="enabled notification"
      src={require("./notification/on/bell.svg").default}
    />
  );
}

export function NotiOff() {
  return (
    <img
      style={styles.icon}
      alt="disabled notification"
      src={require("./notification/off/crossBell.svg").default}
    />
  );
}

export function UncheckedCheckbox() {
  return (
    <img
      style={styles.icon}
      alt="unchecked checkbox"
      src={require("./checkBox/unchecked/rectangle.png").default}
      srcSet={`
            ${require("./checkBox/unchecked/rectangle@2x.png")} 2x, 
            ${require("./checkBox/unchecked/rectangle@3x.png")} 3x
          `}
    />
  );
}

export function CheckedCheckbox() {
  return (
    <img
      style={styles.icon}
      alt="checked checkbox"
      src={require("./checkBox/checked/checkboxEnabled.png").default}
      srcSet={`
            ${require("./checkBox/checked/checkboxEnabled@2x.png")} 2x, 
            ${require("./checkBox/checked/checkboxEnabled@3x.png")} 3x
          `}
    />
  );
}

export function DisabledCheckbox() {
  return (
    <img
      style={styles.icon}
      alt="disabled checkbox"
      src={require("./checkBox/disabled/checkboxDisabled.png").default}
      srcSet={`
            ${require("./checkBox/disabled/checkboxDisabled@2x.png")} 2x, 
            ${require("./checkBox/disabled/checkboxDisabled@3x.png")} 3x
          `}
    />
  );
}
