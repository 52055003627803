// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from "clsx";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { ModifiersUtils } from "react-day-picker";
import FormHelperText from "@material-ui/core/FormHelperText";
// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles, useTheme } from "@material-ui/styles";
import "react-day-picker/lib/style.css";
import { Portal } from "react-portal";
import { Calendar } from "../../../images";

const CALENDAR_HEIGHT = 320;
const CALENDAR_WIDTH = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  inputContainer: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    borderColor: "#a29791",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f5f4f3",
    width: "fit-content",
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  inputField: {
    padding: "2px",
  },
  calendarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderLeftWidth: "1px",
    borderLeftStyle: "solid",
    borderLeftColor: "#a29791",
  },
  overlayContainer: {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    position: "fixed",
    pointerEvents: "none",
  },
  overlayCentered: {
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "white",
    transform: "translate(-50%,-50%)",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
    pointerEvents: "all",
    height: `${CALENDAR_HEIGHT}px`,
    width: `${CALENDAR_WIDTH}px`,
  },
}));

const format = "DD.MM.YYYY";

function DatePicker({
  value,
  onClick,
  showCalendar,
  locale,
  disabledDays,
  placeholder,
  error,
  helperText,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [err, setErr] = useState(false);
  const inputEl = useRef(null);

  useEffect(() => {
    setErr(error || err);
  }, [error, err]);

  const handleDayChange = selectedDay => {
    setErr(false);
    onClick(selectedDay);
  };

  const validate = inputValue => {
    const date = inputValue && parseDate(inputValue, format, locale);
    if (!inputValue) {
      setErr(false); // no input, that's ok
    } else if (!date) {
      // input cannot be parsed to a date
      setErr(true);
    } else if (ModifiersUtils.dayMatchesModifier(date, disabledDays)) {
      // input is a date but is is now allowed
      setErr(true);
    } else {
      setErr(false);
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function OverlayComponent({ children, ...props }) {
    // Need to delete these values to prevent react props error.
    delete props.classNames;
    delete props.selectedDay;

    // Use input bounding rect to calculate position for calendar popup
    const inputRect = inputEl.current.getBoundingClientRect();

    // Calendar popup is by default positioned below the parent control
    // Exception: if there is not enough space under parent, then
    // place the popup so that it fits the visible area
    let topAdjustment = 0;
    if (
      window.innerHeight -
        (inputRect.top + CALENDAR_HEIGHT + inputRect.height + 2) <
      0
    ) {
      topAdjustment =
        window.innerHeight -
        (inputRect.top + CALENDAR_HEIGHT + inputRect.height + 2);
    }
    // Renders the date picker input as a direct child of <Body>
    return (
      <div {...props}>
        <Portal>
          <div className={classes.overlayContainer}>
            <div
              className={classes.overlayCentered}
              style={{
                left: `${CALENDAR_WIDTH / 2 + inputRect.left}px`,
                top: `${
                  CALENDAR_HEIGHT / 2 +
                  inputRect.top +
                  inputRect.height +
                  topAdjustment
                }px`,
              }}
            >
              {children}
            </div>
          </div>
        </Portal>
      </div>
    );
  }

  OverlayComponent.propTypes = {
    children: PropTypes.shape({}).isRequired,
    classNames: PropTypes.shape({}).isRequired,
    selectedDay: PropTypes.instanceOf(Date).isRequired,
  };

  return (
    <div>
      <div
        className={clsx(
          classes.inputContainer,
          err ? classes.error : undefined
        )}
      >
        <div className={classes.inputField}>
          <DayPickerInput
            // eslint-disable-next-line react/jsx-no-bind
            overlayComponent={OverlayComponent}
            format={format}
            formatDate={formatDate}
            placeholder={placeholder}
            parseDate={parseDate}
            dayPickerProps={{
              locale,
              localeUtils: MomentLocaleUtils,
              showWeekNumbers: true,
              disabledDays,
              firstDayOfWeek: 1,
            }}
            inputProps={{
              ref: inputEl,
              style: {
                border: "none",
                textAlign: "center",
                width: "7.625rem",
                height: "2.25rem",
                color: err
                  ? theme.palette.error.main
                  : theme.palette.text.primary,
                ...theme.typography.button,
              },
              onBlur: event => {
                const inputValue = event.target.value;
                validate(inputValue);
              },
            }}
            value={value}
            onDayChange={handleDayChange}
            clickUnselectsDay
          />
        </div>
        {showCalendar && (
          <div className={classes.calendarContainer}>
            <Calendar />
          </div>
        )}
      </div>
      {err && helperText && (
        <FormHelperText error={err}>{helperText}</FormHelperText>
      )}
    </div>
  );
}

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onClick: PropTypes.func,
  showCalendar: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  disabledDays: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.instanceOf(Date),
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.func,
  ]).isRequired,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

DatePicker.defaultProps = {
  value: new Date(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  showCalendar: true,
  error: false,
  helperText: `Invalid date, use format ${format}`,
};

export default DatePicker;
