import moment from "moment";
import LZUTF8 from "lzutf8";
import { jwtDecode } from "jwt-decode";
import { RestService } from "../../components/generic";
import ensureTrailingSlash from "../url/url";

export const openLoginPage = location => {
  // redirect to login page
  const appId = process.env.REACT_APP_ORIGO_APP_ID;
  const authUrl = ensureTrailingSlash(process.env.REACT_APP_ORIGO_URL);
  const authApi = process.env.REACT_APP_ORIGO_AUTH_API;
  const path = `${authUrl}${authApi}?app_id=${appId}`;
  location.replace(path);
};

export const openPortal = location => {
  location.replace(process.env.REACT_APP_ORIGO_URL);
};

export const isTokenExpired = tokenData => {
  if (tokenData === null) {
    return true;
  }

  const { expiryTime } = tokenData;
  return moment().isAfter(expiryTime);
};

const addExpiryTimeToTokenData = data => {
  const expiresInSeconds = data.expiresIn;
  return {
    ...data,
    expiryTime: moment().add(expiresInSeconds, "second"),
  };
};

export const fetchTokenData = sessionId =>
  RestService.getToken(sessionId)
    .then(token => Promise.resolve(addExpiryTimeToTokenData(token)))
    .catch(err => Promise.reject(err));

export const parseUserDataFromToken = userDataJson => {
  try {
    const organizations = JSON.parse(userDataJson);
    const appId = process.env.REACT_APP_ORIGO_APP_ID;

    // gather
    const parsed = organizations.reduce(
      (acc, org) => {
        const result = acc;
        result.organizationIds.push(org.orgId);
        result.profitCenters.push(...org.profitCenters);
        const app = org.apps.find(x => x.id === appId);
        const roles = app ? app.roles : [];
        result.roles.push(...roles);
        return result;
      },
      {
        roles: [],
        organizationIds: [],
        profitCenters: [],
      }
    );

    // return without duplicates
    return {
      roles: Array.from(new Set(parsed.roles)),
      organizationIds: parsed.organizationIds,
      profitCenters: Array.from(new Set(parsed.profitCenters)),
    };
  } catch (error) {
    console.error("Error parsing user data from token", error);
    return {
      roles: [],
      organizations: [],
      profitCenters: [],
    };
  }
};

export const decompressUserData = data =>
  LZUTF8.decompress(data, { inputEncoding: "Base64" });

export const parseUserData = idToken => {
  const payload = jwtDecode(idToken);
  const name = `${payload.given_name} ${payload.family_name}`;
  const { email } = payload;
  const userId = payload["cognito:username"];
  const userDataDecompressed = decompressUserData(payload.user_data);
  const tokenData = parseUserDataFromToken(userDataDecompressed);

  // log for now
  console.log("User roles:", tokenData.roles);

  return {
    userId,
    name,
    email,
    roles: tokenData.roles,
    organizationIds: tokenData.organizationIds,
    profitCenters: tokenData.profitCenters,
  };
};
