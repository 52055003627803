const Decimal = require("decimal.js-light");

const DEFAULT_LANG = "fi-FI";

export const formatVatRate = (vatRate, lang = DEFAULT_LANG) => {
  if (!vatRate || !Number.isFinite(+vatRate)) {
    return "";
  }

  const vatStr = Number(new Decimal(+vatRate)).toLocaleString(lang, {
    maximumFractionDigits: 1,
  });

  return `${vatStr} %`;
};

export const getVatInfo = (price, fetchingPrices, t) => {
  if (price) {
    return formatVatRate(price.vatRate);
  }
  return fetchingPrices
    ? `${t("productManagementTable.loading")} ..`
    : t("productManagementTable.vatNotAvailable");
};
