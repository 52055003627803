import moment from "moment";

export const isAnotherUserNotification = (notification, user) =>
  !!(
    notification.userId !== user.userId &&
    notification.name &&
    notification.creationDate
  );

export const prepareNotificationCheckboxOptions = (
  notifications,
  emails,
  user,
  productId
) => {
  let checkboxOptions = [];
  // Prioritize existing notifications first
  // If no notifications, add emails from provided email array
  if (notifications.length === 0) {
    emails.forEach(email => {
      checkboxOptions.push({ email });
    });
  } else {
    // Filter out other people personal email subs, remaining potentially only this user's personal email
    // and pharmacy email subs
    checkboxOptions = notifications.reduce((acc, n) => {
      if (emails.includes(n.email) && n.productId === productId) {
        acc.push({ ...n });
      }
      return acc;
    }, []);

    // If no personal email yet (existing notis however), add it to start
    if (
      checkboxOptions.every(
        n => n.email !== user.email && n.productId === productId
      )
    ) {
      checkboxOptions.unshift({ email: user.email });
    }

    // Get only pharmacy emails
    const pharmacyEmails = [...emails].slice(1);

    // By now we must have personal email in the options array
    // But notis do not contain pharmacy emails
    // Check and add remaining pharmacy emails
    pharmacyEmails.forEach(email => {
      if (
        !notifications.some(n => n.email === email && n.productId === productId)
      ) {
        checkboxOptions.push({ email });
      }
    });
  }

  // Get only items with unique emails
  checkboxOptions = [
    ...new Map(checkboxOptions.map(item => [item.email, item])).values(),
  ];

  // Re-order items with user email item always comes first
  checkboxOptions = checkboxOptions.reduce((acc, n) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    n.email === user.email ? acc.unshift(n) : acc.push(n);
    return acc;
  }, []);

  return checkboxOptions;
};

export const formatDateToMMDDYYYY = dateString =>
  moment(new Date(dateString)).format("MM.DD.YYYY");

export const addOrRemoveItemsToArray = (
  inputArray,
  item,
  actionType = "",
  notifications = [],
  productId = ""
) => {
  const resultArray = [...inputArray];
  const isEmailAlreadySubscribed = notifications.some(
    n => n.email === item && n.productId === productId
  );

  if (actionType === "add-subscription" && !isEmailAlreadySubscribed) {
    resultArray.push(item);
    return resultArray;
  }
  if (actionType === "add-unsubscription") {
    resultArray.push(item);
    return resultArray;
  }
  return resultArray.filter(i => i !== item);
};

export const shouldDisableStockNotificationConfirmButton = (
  originalCheckBoxStatus,
  checkBoxStatus
) => {
  const origObjKeys = Object.getOwnPropertyNames(originalCheckBoxStatus);
  const currentObjKeys = Object.getOwnPropertyNames(checkBoxStatus);
  if (origObjKeys.length !== currentObjKeys.length) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of origObjKeys) {
    if (!Object.hasOwn(checkBoxStatus, key)) {
      return false;
    }
    if (originalCheckBoxStatus[key] !== checkBoxStatus[key]) {
      return false;
    }
  }
  return true;
};

export const getExistingNotificationsStatus = (notifications, productId) =>
  notifications.reduce((acc, notification) => {
    if (notification.productId === productId) {
      acc[notification.email] = true;
    }
    return acc;
  }, {});

export const prepareNotificationIdsToDelete = (
  notifications,
  productId,
  emailsForUnsubscription
) => {
  const notificationIdsToDelete = notifications
    .filter(n => n.productId === productId)
    .reduce((acc, n) => {
      if (emailsForUnsubscription.includes(n.email)) {
        acc.push(n.notificationId);
      }
      return acc;
    }, []);
  return notificationIdsToDelete;
};

export const prepareUniqueEmailsForStockNotifications = (
  userData,
  customers,
  selectedCustomerId
) => {
  const rawEmails = [];
  rawEmails.push(userData.email);

  if (Array.isArray(customers) && customers.length > 0) {
    customers.forEach(c => {
      // Consider currently selected customer and sub org with AddrRemark (no robots)
      if (c.customerId === selectedCustomerId || c.AddrRemark) {
        rawEmails.push(c.EmailAddr);
        rawEmails.push(c.EmailAddr2);
      }
    });
  }
  return [...new Set(rawEmails.filter(Boolean))];
};
