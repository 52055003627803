const EventType = Object.freeze({
  PageView: "pageview",
});

const pageViewEvent = action => ({
  hitType: EventType.PageView,
  page: action.payload.location.pathname,
});

export default pageViewEvent;
