import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";

export const Price = Object.freeze({
  PRICE_FETCH_STARTED: "PRICE_FETCH_STARTED",
  PRICE_FETCH_ERROR: "PRICE_FETCH_ERROR",
  PRICE_FETCH_FINISHED: "PRICE_FETCH_FINISHED",
});

const serviceUrl = ensureTrailingSlash(
  process.env.REACT_APP_PRODUCT_MANAGEMENT_API
);
// -- ACTIONS --
// eslint-disable-next-line consistent-return
export const fetchAllPrices = materialIds => async dispatch => {
  try {
    // start
    dispatch({ type: Price.PRICE_FETCH_STARTED });
    const materialIdsParam = `materialIds=${encodeURIComponent(materialIds)}`;
    const path = `${serviceUrl}price/v1?${materialIdsParam}`;
    const prices = await RestService.get(path);

    // update
    dispatch({
      type: Price.PRICE_FETCH_FINISHED,
      payload: prices,
    });

    return prices;
  } catch (error) {
    dispatch({ type: Price.PRICE_FETCH_ERROR, payload: error });
  }
};

// -- REDUCER --

const INIT_STATE = {
  prices: [],
  fetchingPrices: false,
  priceFetchError: null,
};
// eslint-disable-next-line default-param-last
export const priceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Price.PRICE_FETCH_STARTED:
      return { ...state, fetchingPrices: true, priceFetchError: null };
    case Price.PRICE_FETCH_FINISHED:
      return {
        ...state,
        fetchingPrices: false,
        prices: action.payload,
      };
    case Price.PRICE_FETCH_ERROR:
      return {
        ...state,
        fetchingPrices: false,
        priceFetchError: action.payload,
      };
    default:
      return state;
  }
};
