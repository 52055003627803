// eslint-disable-next-line no-use-before-define
import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import ServerError from "../../500/500";

import Can from "./can";

function ProtectedRoute({
  path,
  component,
  failComponent,
  user,
  requiredPermission,
  serverError,
}) {
  if (serverError) {
    return <Route path={path} component={ServerError} />;
  }
  return (
    <Can
      user={user}
      perform={requiredPermission}
      // eslint-disable-next-line react/no-unstable-nested-components
      ok={() => <Route path={path} component={component} />}
      // eslint-disable-next-line react/no-unstable-nested-components
      nok={() => <Route path={path} component={failComponent} />}
    />
  );
}

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  failComponent: PropTypes.elementType.isRequired,
  user: PropTypes.shape({}).isRequired,
  requiredPermission: PropTypes.string.isRequired,
  serverError: PropTypes.shape({}),
};

ProtectedRoute.defaultProps = {
  serverError: null,
};

export default ProtectedRoute;
