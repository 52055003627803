// eslint-disable-next-line no-use-before-define
import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Layout, RestService } from "./components/generic";
import {
  OrigoAuthenticator,
  ProtectedRoute,
  Permission,
} from "./components/auth";
import {
  userSignIn,
  fetchUserDataFromOrigo,
  fetchCustomers,
  setTokenData,
  setSelectedCustomer,
} from "./redux/reducers";
import { Paths } from "./utils/navigation/navigation";
import ProductManagement from "./components/productManagement/productManagement";
import Profile from "./components/profile/profile";
import Faq from "./components/faq/faq";
import ContactUs from "./components/contactUs/contactUs";
import NotFound from "./components/404/404";

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const user = useSelector(state => state.user);
  const { userData } = user;
  const { search } = useLocation();

  if (user.tokenData && user.sessionId) {
    RestService.init(user, res => dispatch(setTokenData(res)));
  }

  // Customer data is required for app to work correctly
  const { customers, selectedCustomerId } = useSelector(
    state => state.customer
  );

  useEffect(() => {
    const organizationId = new URLSearchParams(search).get("organizationId");
    // If query params have organizationId store it
    if (organizationId && organizationId !== selectedCustomerId) {
      dispatch(setSelectedCustomer(organizationId));
      // Use first customer as fallback
    } else if (!selectedCustomerId && customers.length > 0) {
      dispatch(setSelectedCustomer(customers[0].customerId));
    }
  }, [search, selectedCustomerId, customers, dispatch]);

  const initData = signInData => {
    console.log("initData", signInData);

    dispatch(userSignIn(signInData));
    const { userId, organizationIds } = signInData.userData;

    dispatch(fetchUserDataFromOrigo(userId)).then(origoUserData => {
      if (origoUserData && origoUserData.language) {
        i18n.changeLanguage(origoUserData.language);
      }
    });

    dispatch(fetchCustomers(organizationIds));
  };

  return (
    <OrigoAuthenticator onSuccess={signInData => initData(signInData)}>
      <Layout>
        <Switch>
          <ProtectedRoute
            user={userData}
            path={Paths.ProductManagement}
            component={ProductManagement}
            failComponent={NotFound}
            requiredPermission={Permission.AVAILABILITY}
            serverError={null}
          />
          <Route path={Paths.Profile} component={Profile} />
          <Route path={Paths.FAQ} component={Faq} />
          <Route path={Paths.ContactUs} component={ContactUs} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </OrigoAuthenticator>
  );
}

export default App;
