// eslint-disable-next-line no-use-before-define
import React from "react";

function Profile() {
  return <p>Profile</p>;
}

Profile.propTypes = {};

Profile.defaultProps = {};

export default Profile;
