export const Toast = Object.freeze({
  UPDATE_TOAST: "UPDATE_TOAST",
});

// -- ACTIONS --

export const showToast =
  (text, variant, autoHideDuration = null) =>
  async dispatch => {
    dispatch({
      type: Toast.UPDATE_TOAST,
      payload: {
        open: true,
        text,
        variant,
        autoHideDuration,
      },
    });
  };

export const hideToast = () => async (dispatch, getState) => {
  const existing = getState().toast.toast;
  dispatch({
    type: Toast.UPDATE_TOAST,
    payload: {
      ...existing,
      open: false,
    },
  });
};

// -- REDUCER --

const INIT_STATE = {
  open: false,
  text: "",
  variant: "success",
  autoHideDuration: null,
};

export const toastReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Toast.UPDATE_TOAST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
