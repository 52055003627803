import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";

export const NotificationType = Object.freeze({
  EMAIL: "EMAIL",
  SMS: "SMS",
});

export const Notification = Object.freeze({
  FETCH_STARTED: "NOTIFICATION_FETCH_STARTED",
  FETCH_FINISHED: "NOTIFICATION_FETCH_FINISHED",
  FETCH_ERROR: "NOTIFICATION_FETCH_ERROR",
  SEND_STARTED: "NOTIFICATION_SEND_STARTED",
  SEND_FINISHED: "NOTIFICATION_SEND_FINISHED",
  SEND_ERROR: "NOTIFICATION_SEND_ERROR",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
});

const baseUrl = ensureTrailingSlash(
  process.env.REACT_APP_PRODUCT_MANAGEMENT_API
);

export const subscribeStockNotification =
  ({
    userId,
    customerId,
    notificationType,
    productId,
    productName,
    productPackageSize,
    productStrength,
    productVnrOrMsiCode,
    siteUrl,
    emails,
  }) =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    try {
      dispatch({ type: Notification.SEND_STARTED });

      // create request for stock updates
      const { userData } = getState().user;

      const stockSubscription = {
        customerId,
        notificationType,
        emails,
        name: userData.name,
        phoneNumber: userData.phoneNumber,
        language: userData.language,
        productId,
        productName,
        productPackageSize,
        productStrength,
        productVnrOrMsiCode,
        siteUrl,
      };

      const path = `${baseUrl}notification/v1/stock/${userId}`;
      const notificationResult = await RestService.post(
        path,
        stockSubscription
      );

      dispatch({
        type: Notification.SEND_FINISHED,
        payload: {
          notifications: notificationResult,
          analytics: { productId, productName, notificationType },
        },
      });

      return notificationResult;
    } catch (error) {
      dispatch({ type: Notification.SEND_ERROR, payload: error });
    }
  };

// eslint-disable-next-line consistent-return
export const fetchSubscribedNotifications = userId => async dispatch => {
  try {
    dispatch({ type: Notification.FETCH_STARTED });

    const path = `${baseUrl}notification/v1/stock/${userId}`;
    const notificationResult = await RestService.get(path);

    dispatch({
      type: Notification.FETCH_FINISHED,
      payload: notificationResult,
    });

    return notificationResult;
  } catch (error) {
    dispatch({ type: Notification.FETCH_ERROR, payload: error });
  }
};

export const fetchSubscribedNotificationsPerCustomer =
  (customerIds = [], productId = "") =>
  // eslint-disable-next-line consistent-return
  async dispatch => {
    try {
      dispatch({ type: Notification.FETCH_STARTED });

      const allSubscriptions = customerIds.map(customerId => {
        const path = `${baseUrl}notification/v1/stock/customers/${customerId}?productId=${productId}`;
        return RestService.get(path);
      });
      const fetchResult = await Promise.all(allSubscriptions);
      const notificationResult = fetchResult.flatMap(r => r);

      dispatch({
        type: Notification.FETCH_FINISHED,
        payload: notificationResult,
      });

      return notificationResult;
    } catch (error) {
      dispatch({ type: Notification.FETCH_ERROR, payload: error });
    }
  };
export const deleteNotificationSubscription =
  // eslint-disable-next-line consistent-return
  (userId, notificationIds) => async (dispatch, getState) => {
    try {
      dispatch({ type: Notification.SEND_STARTED });

      const path = `${baseUrl}notification/v1/${userId}/?notificationIds=${notificationIds}`;
      const notificationResult = await RestService.delete(path);

      // get notification for analytics
      const notification = getState().notification.notifications.find(
        noti => noti.notificationId === notificationIds.split(",")[0]
      );
      const { productId, productName, notificationType, notificationId } =
        notification;

      dispatch({
        type: Notification.DELETE_NOTIFICATION,
        payload: {
          notificationId,
          analytics: { productId, productName, notificationType },
        },
      });

      return notificationResult;
    } catch (error) {
      dispatch({ type: Notification.SEND_ERROR, payload: error });
    }
  };

const INIT_STATE = {
  notifications: [],
  notificationFetching: false,
  notificationFetchError: null,
  notificationSending: false,
  notificationSendError: null,
};
// eslint-disable-next-line default-param-last
export const notificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Notification.FETCH_STARTED: {
      return {
        ...state,
        notificationFetching: true,
        notificationFetchError: null,
      };
    }
    case Notification.FETCH_FINISHED: {
      return {
        ...state,
        notificationFetching: false,
        notifications: action.payload,
      };
    }
    case Notification.FETCH_ERROR:
      return {
        ...state,
        notificationFetching: false,
        notificationFetchError: action.payload,
      };
    case Notification.SEND_STARTED:
      return {
        ...state,
        notificationSending: true,
        notificationSendError: null,
      };
    case Notification.SEND_FINISHED: {
      const { notifications } = action.payload;
      return {
        ...state,
        notificationSending: false,
        notifications,
        notificationSendError: null,
      };
    }
    case Notification.SEND_ERROR:
      return {
        ...state,
        notificationSending: false,
        notificationSendError: action.payload,
      };
    case Notification.DELETE_NOTIFICATION: {
      const { notificationId } = action.payload;
      const notifications = state.notifications.filter(
        x => x.notificationId !== notificationId
      );
      return { ...state, notificationSending: false, notifications };
    }

    default:
      return state;
  }
};
