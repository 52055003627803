/* Permission rules for roles:
 * object key = role
 * static = a list of static permissions used in this application to restrict access / visibility
 * dynamic = functions for determining access based on data
 */
export const Permission = Object.freeze({
  AVAILABILITY: "availability",
  MANAGEMENT_ORIOLA: "management:oriola",
  MANAGEMENT_PHARMACY: "management:pharmacy",
  MANAGEMENT_PHARMACOMPANY: "management:pharmacompany",
  MANAGEMENT_AUTHORITY_VIEW: "management:authority",
});

export const Roles = Object.freeze({
  ORIOLA_EDIT_ROLE: "productmgmt_oriola_edit",
  PHARMA_COMPANY_EDIT_ROLE: "productmgmt_pharma_edit",
  PHARMACY_VIEW_ROLE: "productmgmt_pharmacy_view",
  VET_VIEW_ROLE: "productmgmt_vet_view",
  AUTHORITY_VIEW_ROLE: "productmgmt_authority_view",
});

const rules = {
  productmgmt_oriola_edit: {
    static: [Permission.AVAILABILITY, Permission.MANAGEMENT_ORIOLA],
  },
  productmgmt_pharma_edit: {
    static: [Permission.AVAILABILITY, Permission.MANAGEMENT_PHARMACOMPANY],
  },
  productmgmt_pharmacy_view: {
    static: [Permission.AVAILABILITY, Permission.MANAGEMENT_PHARMACY],
  },
  productmgmt_vet_view: {
    static: [Permission.AVAILABILITY, Permission.MANAGEMENT_PHARMACY],
  },
  productmgmt_authority_view: {
    static: [
      Permission.AVAILABILITY,
      Permission.MANAGEMENT_PHARMACY,
      Permission.MANAGEMENT_AUTHORITY_VIEW,
    ],
  },
};

const getStaticPermissionsForRole = role => (rules[role] || {}).static || [];

const getDynamicPermissionsForRole = role => (rules[role] || {}).dynamic || [];

const checkForRole = (role, action, data) => {
  if (!role) {
    return false;
  }

  const staticPermissions = getStaticPermissionsForRole(role);
  if (staticPermissions.includes(action)) {
    return true;
  }

  const dynamicPermissions = getDynamicPermissionsForRole(role);
  if (dynamicPermissions[action]) {
    return dynamicPermissions[action](data);
  }
  return false;
};

export const isAllowed = (user, action, data) =>
  (user.roles || []).some(role => checkForRole(role, action, data));

export const isPharmaCompanyUser = user =>
  (user.roles || []).includes(Roles.PHARMA_COMPANY_EDIT_ROLE);

export const isOriolaUser = user =>
  (user.roles || []).includes(Roles.ORIOLA_EDIT_ROLE);
