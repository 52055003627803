// eslint-disable-next-line no-use-before-define
import React from "react";

function Faq() {
  return <p>FAQ</p>;
}

Faq.propTypes = {};

Faq.defaultProps = {};

export default Faq;
