// eslint-disable-next-line no-use-before-define
import React from "react";
import { Route, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import NotFound from "../../404/404";

const isDevEnv = process.env.REACT_APP_STAGE === "dev";

function ShowOnlyInDevelopment({ children, isRoute, path }) {
  const location = useLocation();
  if (isRoute) {
    return isDevEnv && path === location.pathname ? (
      children
    ) : (
      <Route path={location.pathname} component={NotFound} />
    );
  }

  return isDevEnv ? children : null;
}

ShowOnlyInDevelopment.propTypes = {
  isRoute: PropTypes.bool,
  path: PropTypes.string.isRequired,
  children: PropTypes.node,
};
ShowOnlyInDevelopment.defaultProps = {
  isRoute: false,
  children: null,
};

export default ShowOnlyInDevelopment;
