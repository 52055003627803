import { createMuiTheme } from "@material-ui/core";
import colors from "./colors";

const oriolaTheme = createMuiTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1600 },
  },
  palette: {
    primary: {
      light: colors.backgroundLightOrange,
      main: colors.primaryOrange,
      contrastText: colors.white,
    },
    secondary: {
      light: colors.backgroundDarkGray,
      main: colors.secondaryNavyBlue,
      contrastText: colors.white,
    },
    // error: will use the default color
    background: { paper: colors.white, default: colors.backgroundGrayLight },
    action: {
      hover: colors.hoverOrange,
      selected: colors.hoverOrange,
      unselected: colors.secondaryGray,
    },
    text: {
      primary: colors.secondaryNavyBlue,
      secondary: colors.white,
      disabled: colors.secondaryDarkGray,
    },
    divider: colors.secondaryGray,
    tableDivider: {
      header: colors.secondaryDarkGray,
      normal: colors.backgroundDarkGray,
    },
    error: {
      main: colors.errorRed,
    },
  },
  typography: {
    fontFamily: ["verdana"].join(","),
    fontSize: 16,
    lineHeight: 25,
    letterSpacing: 0.4,
    // these are defined from here
    // https://app.zeplin.io/project/5c3d8c03b2bfa352560dd752/screen/5d70c0d3c07a8762570a9dd6
    // 25px bold
    h4: {
      fontFamily: "verdana",
      fontWeight: "bold",
      fontSize: "1.5625rem",
      lineHeight: 1.2,
    },
    // 20px bold
    h5: {
      fontFamily: "verdana",
      fontWeight: "bold",
      fontSize: "1.25rem",
      lineHeight: 1.2,
    },
    // 20px regular
    h6: {
      fontFamily: "verdana",
      fontWeight: "normal",
      fontSize: "1.25rem",
      lineHeight: 1.2,
    },
    // 18 px bold
    subtitle1: {
      fontFamily: "verdana",
      fontWeight: "bold",
      fontSize: "1.125rem",
      lineHeight: 1.22,
    },
    // 16 px bold
    subtitle2: {
      fontFamily: "verdana",
      fontWeight: "bold",
      fontSize: "1rem",
      lineHeight: 1.19,
    },
    // 16 px normal
    body1: {
      fontFamily: "verdana",
      fontWeight: "normal",
      fontSize: "1rem",
      lineHeight: 1.19,
    },
    // 12 px normal
    body2: {
      fontFamily: "verdana",
      fontWeight: "normal",
      fontSize: "0.75rem",
      lineHeight: 1.25,
    },
    // 14px normal
    button: {
      fontFamily: "verdana",
      fontWeight: "normal",
      fontSize: "0.875rem",
      lineHeight: 1.21,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 2,
        textTransform: "none",
      },
      sizeSmall: {
        fontSize: "1rem",
      },
    },
    MuiTextField: {
      root: {
        "& input::-ms-clear": {
          display: "none",
        },
      },
    },
    MuiTable: {
      root: {
        "& a": {
          textDecoration: "none",
          color: colors.secondaryNavyBlue,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "inherit",
        borderBottomColor: colors.secondaryGray,
        borderBottomStyle: "inset",
        borderBottomWidth: "1px",
      },
    },
  },
});

export default oriolaTheme;
