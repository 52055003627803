// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { More, Less } from "../../images";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  iconButton: {
    padding: "0px 10px 0px 0px",
    marginLeft: "0px",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    color: theme.palette.text.disabled,
    fontSize: "1rem",
    fontWeight: "bold",
    marginLeft: theme.spacing(1),
  },
  clickableText: {
    color: theme.palette.text.disabled,
    fontSize: "1rem",
    fontWeight: "bold",
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  tableTitle: {
    marginBottom: theme.spacing(2),
    marginLeft: "13px",
    color: theme.palette.text.disabled,
  },
  noItemsMessage: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginLeft: "40px",
    color: theme.palette.text.disabled,
  },
}));

function MoreOrLess({
  enabled,
  value,
  text,
  count,
  onToggleExpand,
  children,
  noItemsText,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(value);

  useEffect(() => {
    onToggleExpand(expanded);
  }, [expanded, onToggleExpand]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function MoreOrLessButton() {
    return (
      <IconButton
        size="small"
        className={classes.iconButton}
        onClick={() => toggleExpanded()}
      >
        {expanded ? <Less /> : <More />}
      </IconButton>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="subtitle2" className={classes.tableTitle}>
          {enabled && <MoreOrLessButton />}
          {`${text} (${count})`}
        </Typography>
      </div>
      {count === 0 && (
        <Typography variant="body1" className={classes.noItemsMessage}>
          {noItemsText}
        </Typography>
      )}
      {children}
    </div>
  );
}

MoreOrLess.propTypes = {
  enabled: PropTypes.bool,
  value: PropTypes.bool,
  text: PropTypes.string,
  count: PropTypes.number,
  onToggleExpand: PropTypes.func,
  noItemsText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

MoreOrLess.defaultProps = {
  enabled: true,
  value: false,
  text: "",
  count: "",
  noItemsText: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onToggleExpand: () => {},
  children: null,
};

export default MoreOrLess;
