import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";

export const SCC = Object.freeze({
  SCC_FETCH_STARTED: "SCC_FETCH_STARTED",
  SCC_FETCH_ERROR: "SCC_FETCH_ERROR",
  SCC_FETCH_FINISHED: "SCC_FETCH_FINISHED",
});

// -- ACTIONS --

export const fetchSccInformation = () => async (dispatch, getState) => {
  const origoBaseUrl = ensureTrailingSlash(process.env.REACT_APP_ORIGO_URL);
  const sccDataApi = ensureTrailingSlash(
    process.env.REACT_APP_ORIGO_SCC_DATA_API
  );
  const path = `${origoBaseUrl}api/v1/${sccDataApi}`;
  try {
    // start
    dispatch({ type: SCC.SCC_FETCH_STARTED });
    console.time("fetchSccInformation");
    const { tokenData } = getState().user;
    const config = RestService.createConfig(tokenData.accessToken);
    const sccData = await RestService.get(path, config);
    console.timeEnd("fetchSccInformation");
    // update
    dispatch({
      type: SCC.SCC_FETCH_FINISHED,
      payload: sccData,
    });

    return sccData;
  } catch (error) {
    dispatch({ type: SCC.SCC_FETCH_ERROR, payload: error });
  }
  return {};
};

// -- REDUCER --

const INIT_STATE = {
  sccInformation: [],
  sccFetchError: null,
  fetchingSccInformation: false,
};

// eslint-disable-next-line default-param-last
export const sccReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SCC.SCC_FETCH_STARTED:
      return { ...state, fetchingSccInformation: true, sccFetchError: null };
    case SCC.SCC_FETCH_FINISHED:
      return {
        ...state,
        fetchingSccInformation: false,
        sccInformation: action.payload,
      };
    case SCC.SCC_FETCH_ERROR:
      return {
        ...state,
        fetchingSccInformation: false,
        sccFetchError: action.payload,
      };
    default:
      return state;
  }
};
