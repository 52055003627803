import ensureTrailingSlash from "../url/url";

export const Paths = {
  ProductManagement: "/",
  Profile: "/profile",
  FAQ: "/faq",
  ContactUs: "/contactUs",
};

const eComUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_URL);
export const EcomUrls = {
  Products: `${eComUrl}products`,
  Reports: `${eComUrl}reports`,
  OrdersInvoices: `${eComUrl}ordersInvoices`,
  ShoppingCart: `${eComUrl}shoppingCart`,
  OrdersInvoicesHistory: `${eComUrl}ordersInvoices/history`,
};
