// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CheckboxItem from "../checkboxItem/checkboxItem";
import "./stockNotificationSelection.css";
import {
  isAnotherUserNotification,
  prepareNotificationCheckboxOptions,
  formatDateToMMDDYYYY,
} from "../../../utils/stockNotificationUtil";

function StockNotificationSelection({
  onChange,
  emails,
  checkboxItemsStatus,
  notifications = [],
  user = {},
  productId = "",
}) {
  const { t } = useTranslation();
  const checkboxOptions = prepareNotificationCheckboxOptions(
    notifications,
    emails,
    user,
    productId
  );

  return (
    <div className="stock-notification__container">
      {checkboxOptions.map(option => (
        <CheckboxItem
          key={option.email}
          onChange={onChange}
          checked={checkboxItemsStatus[option.email]}
          disabled={isAnotherUserNotification(option, user)}
          value={option.email}
          label={
            option.email === user.email ? t("notifyMe") : t("notifyOthers")
          }
          extraText={`${t("subscribed")} ${option.name} ${formatDateToMMDDYYYY(
            new Date(option.creationDate)
          )}`}
          showExtraText={isAnotherUserNotification(option, user)}
        />
      ))}
    </div>
  );
}

StockNotificationSelection.propTypes = {
  onChange: PropTypes.func,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  checkboxItemsStatus: PropTypes.shape({}).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({}),
  productId: PropTypes.string,
};

StockNotificationSelection.defaultProps = {
  notifications: [],
  user: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
  productId: "",
};

export default StockNotificationSelection;
