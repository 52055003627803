// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { Button, IconTextLabel, Progress } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { exportAvailabilityToExcel, showToast } from "../../redux/reducers";
import { ImportExcelIcon } from "../../images";

function ExportReport({ classes, searchTerm, selectedScc }) {
  const { fetchingExcelReport } = useSelector(state => state.availability);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const exportToExcel = () => {
    const sccId = selectedScc ? selectedScc.id : "";
    dispatch(exportAvailabilityToExcel(searchTerm, sccId, i18n.language))
      .then(response => {
        window.open(response);
      })
      .catch(error => {
        dispatch(showToast(t("exportToExcelFailed"), "error", 6000));
        console.error("Error when importing the excel", error);
      });
  };
  return (
    <div className={classes.exportButtonWrapper}>
      {fetchingExcelReport ? (
        <Progress className={classes.exportLoader} show />
      ) : (
        <Button
          className={classes.exportButton}
          onClick={exportToExcel}
          variant="transparent"
        >
          <IconTextLabel
            icon={<ImportExcelIcon />}
            text={t("exportToExcel")}
            variant="body1"
          />
        </Button>
      )}
    </div>
  );
}

ExportReport.propTypes = {
  searchTerm: PropTypes.string,
  classes: PropTypes.shape({
    exportButtonWrapper: PropTypes.string,
    exportButton: PropTypes.string,
    exportLoader: PropTypes.string,
  }),
  selectedScc: PropTypes.shape({
    id: PropTypes.string,
  }),
};

ExportReport.defaultProps = {
  searchTerm: "",
  classes: {},
  selectedScc: null,
};

export default ExportReport;
