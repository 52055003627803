// eslint-disable-next-line no-use-before-define
import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  selectForm: {},
  selectField: {
    background: "#F5F4F3",
    height: "2.5rem",
  },
}));

function SCCFilterSelection({ onChange, sccs, selectedScc, disabled }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderOption = (optionLabel, option, index) => (
    <MenuItem key={`select-option-${index}`} value={option}>
      <Typography>{optionLabel}</Typography>
    </MenuItem>
  );

  const getDisplayName = option => `${option.givenName} ${option.familyName}`;

  return (
    <FormControl
      fullWidth
      margin="normal"
      variant="filled"
      className={classes.selectForm}
    >
      <Select
        className={classes.selectField}
        value={selectedScc || ""}
        disabled={disabled}
        input={<OutlinedInput name="selection" />}
        onChange={event => onChange(event.target.value)}
        displayEmpty
        renderValue={value => {
          const text =
            value && value !== "" ? getDisplayName(value) : t("selectScc");
          return <Typography>{text}</Typography>;
        }}
      >
        {renderOption(t("selectScc"), null, 0)}
        {sccs.map((option, idx) =>
          renderOption(getDisplayName(option), option, idx + 1)
        )}
      </Select>
    </FormControl>
  );
}

SCCFilterSelection.propTypes = {
  onChange: PropTypes.func,
  sccs: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  selectedScc: PropTypes.shape({}),
};
SCCFilterSelection.defaultProps = {
  onChange: null,
  disabled: false,
  sccs: [],
  selectedScc: null,
};

export default memo(SCCFilterSelection);
