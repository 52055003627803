import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { userReducer } from "./user/user";
import { availabilityReducer } from "./availability/availability";
import { priceReducer } from "./price/price";
import { notificationReducer } from "./notification/notification";
import { customerReducer } from "./customer/customer";
import { sccReducer } from "./scc/scc";
import { profitCenterReducer } from "./profitCenter/profitCenter";
import { toastReducer } from "./toast/toast";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    availability: availabilityReducer,
    price: priceReducer,
    notification: notificationReducer,
    customer: customerReducer,
    scc: sccReducer,
    profitCenter: profitCenterReducer,
    toast: toastReducer,
  });
export default createRootReducer;
