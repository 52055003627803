// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { Search } from "../../images";

const useStyles = makeStyles(theme => ({
  searchField: {
    marginTop: theme.spacing(4),
    backgroundColor: "#F5F4F3",
    borderRadius: 10,
    width: "100%",
  },
}));

function SearchField({ onSearch, placeholder }) {
  const classes = useStyles();
  const [value, setValue] = useState("");

  // debouncing the search
  useEffect(() => {
    const timer = setTimeout(() => {
      onSearch(value);
    }, 700);

    return () => clearTimeout(timer);
  }, [value, onSearch]);

  const resetSearch = () => {
    setValue("");
    onSearch("");
  };

  return (
    <TextField
      id="outlined-dense"
      placeholder={placeholder}
      className={classes.searchField}
      margin="none"
      size="small"
      variant="outlined"
      value={value}
      onChange={e => setValue(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value !== "" && (
              <IconButton size="small" onClick={() => resetSearch()}>
                <ClearIcon />
              </IconButton>
            )}
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}

SearchField.propTypes = {
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
};

SearchField.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSearch: () => {},
  placeholder: "",
};

export default SearchField;
