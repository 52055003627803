// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
// eslint-disable-next-line import/no-cycle
import { Progress } from "..";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& a": {
      textDecoration: "none",
    },
  },
  list: {
    outline: "none",
    overflowX: "hidden !important;",
    // InnerScrollContainer must use inherit width to ignore the scaling issues caused by scrollbar width variation.
    "& .ReactVirtualized__Grid__innerScrollContainer": {
      width: "inherit !important",
    },
  },
  progress: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  progressTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
    position: "absolute",
  },
}));

function InfinityList({
  items,
  visibleItemCount,
  itemOverallCount,
  rowHeight,
  rowSpacing,
  getRowHeight,
  isLoading,
  onRenderRow,
  onLoad,
}) {
  const classes = useStyles();
  const overallRowHeight = rowHeight + rowSpacing;
  const listHeight = overallRowHeight * visibleItemCount;

  return (
    <div className={classes.root}>
      <div style={{ height: listHeight, width: "100%" }}>
        <div className={classes.progressTop}>
          <Progress size={40} show={isLoading} />
        </div>
        <InfiniteLoader
          isRowLoaded={() => false}
          loadMoreRows={({ startIndex, stopIndex }) =>
            onLoad(startIndex, stopIndex)
          }
          rowCount={itemOverallCount || 1000}
          minimumBatchSize={visibleItemCount}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  className={classes.list}
                  height={height}
                  width={width}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  rowCount={items.length}
                  rowHeight={getRowHeight || overallRowHeight}
                  rowRenderer={onRenderRow}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
      <div className={classes.progress}>
        <Progress size={40} show={isLoading} />
      </div>
    </div>
  );
}

InfinityList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRenderRow: PropTypes.func.isRequired,
  rowHeight: PropTypes.number,
  getRowHeight: PropTypes.func,
  rowSpacing: PropTypes.number,
  onLoad: PropTypes.func,
  visibleItemCount: PropTypes.number,
  itemOverallCount: PropTypes.number,
  isLoading: PropTypes.bool,
};

InfinityList.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLoad: () => {},
  rowHeight: 60,
  rowSpacing: 0,
  visibleItemCount: 10,
  itemOverallCount: 10,
  isLoading: false,
  getRowHeight: null,
};

export default InfinityList;
