import colors from "../../theme/colors";

const styles = theme => ({
  tableContainer: {
    marginBottom: theme.spacing(4),
  },
  table: {
    display: "flex",
    flexDirection: "column",
  },
  column: {
    overflow: "hidden",
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    paddingBottom: theme.spacing(1),
    marginLeft: "5px",
    fontSize: "0.875rem",
    color: theme.palette.text.disabled,
  },
  tableTitle: {
    marginBottom: theme.spacing(2),
    marginLeft: "13px",
    color: theme.palette.text.disabled,
  },
  tableHeadCell: {
    fontSize: "0.875rem",
    color: theme.palette.text.disabled,
    border: "none",
  },
  ellipsisText: {
    fontSize: "1rem",
    overflow: "hidden",
    marginRight: theme.spacing(2),
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tableRow: {
    width: "100%",
    height: "80px",
    display: "flex",
    borderBottom: `1px solid ${colors.backgroundGray}`,
    flexDirection: "row",
  },
  product: {
    flex: "1 0 25%",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "&:hover": {
      background: colors.hoverGray,
    },
  },
  principal: {
    flex: "1 0 8%",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  estimated: {
    flex: "1 0 10%",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  availability: {
    flex: "1 0 10%",
    minWidth: "180px",
    paddingTop: theme.spacing(1),
  },
  information: {
    flex: "1 0 13%",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  informationPermanent: {
    flex: "1 0 8%",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  notify: {
    flex: "1 0 8%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  update: {
    flex: "1 0 10%",
    textAlign: "center",
    paddingTop: theme.spacing(1),
  },
  textField: {
    margin: 0,
    width: "96%",
  },
  infoText: {
    fontSize: "0.875rem",
    color: theme.palette.text.disabled,
  },
  highlightRow: {
    backgroundColor: colors.backgroundGrayLight,
    borderLeft: `5px solid ${colors.primaryOrange}`,
  },
  higlightOffsetMargin: {
    marginLeft: "5px",
  },
  notifyButton: {
    backgroundColor: theme.palette.background.default,
    textTransform: "none",
    height: "2.5rem",
    fontSize: "1rem",
    width: "100%",
    maxWidth: "200px",
    textAlign: "left",
    justifyContent: "left",
  },
  primaryButton: {
    /* Primary button with icon */
    height: "39px",
    background: colors.secondaryNavyBlue,
    boxShadow: "rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    marginBottom: "47px",
    "& p": {
      fontFamily: "Verdana",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      color: colors.white,
    },
  },
  secondaryButton: {
    height: "39px",
    background: colors.backgroundGray,
    boxShadow: "rgba(0, 0, 1, 0.25)",
    borderRadius: "4px",
    marginBottom: "47px",
    "& p": {
      fontFamily: "Verdana",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      color: colors.secondaryNavyBlue,
    },
  },
  notifyProgress: {
    display: "flex",
    justifyContent: "center",
  },
  latestUpdateLabel: {
    fontSize: "0.75rem",
    color: colors.secondaryDarkGray,
  },
  updatedLabel: {
    backgroundColor: colors.primaryOrange,
    color: colors.secondaryNavyBlue,
    borderRadius: "5px",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textAlign: "center",
    maxWidth: "75px",
    marginTop: "4px",
  },
  waitingActionsLabel: {
    backgroundColor: colors.errorRed,
    color: colors.white,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    borderRadius: "10px",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "4px",
    maxWidth: "130px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  newAvailabilityNeededLabel: {
    backgroundColor: colors.primaryOrange,
    color: colors.white,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    borderRadius: "10px",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "4px",
    maxWidth: "152px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  informationSpecification: {
    fontSize: "0.625rem",
    color: colors.secondaryNavyBlue,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  primaryText: {
    fontSize: ".925rem",
    color: theme.palette.text.primary,
  },
  profitCenter: {
    color: theme.palette.text.primary,
    marginBottom: "0.25rem",
  },
  secondaryText: {
    fontSize: "0.875rem",
    color: theme.palette.text.disabled,
  },
});

export default styles;
