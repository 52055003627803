// eslint-disable-next-line no-use-before-define
import React from "react";

function ContactUs() {
  return <p>ContactUs</p>;
}

ContactUs.propTypes = {};

ContactUs.defaultProps = {};

export default ContactUs;
