import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";

export const Availability = Object.freeze({
  AVAILABILITY_FETCH_STARTED: "AVAILABILITY_FETCH_STARTED",
  AVAILABILITY_FETCH_ERROR: "AVAILABILITY_FETCH_ERROR",
  AVAILABILITY_FETCH_FINISHED: "AVAILABILITY_FETCH_FINISHED",
  AVAILABILITY_SEND_STARTED: "AVAILABILITY_SEND_STARTED",
  AVAILABILITY_SEND_ERROR: "AVAILABILITY_SEND_ERROR",
  AVAILABILITY_SEND_FINISHED: "AVAILABILITY_SEND_FINISHED",
  UPDATE_AVAILABILITY_LOCAL_CHANGES: "UPDATE_AVAILABILITY_EDIT_STATUS",
  EXPORT_TO_EXCEL_STARTED: "EXPORT_TO_EXCEL_STARTED",
  EXPORT_TO_EXCEL_FINISHED: "EXPORT_TO_EXCEL_FINISHED",
  EXPORT_TO_EXCEL_ERROR: "EXPORT_TO_EXCEL_ERROR",
});

const serviceUrl = ensureTrailingSlash(
  process.env.REACT_APP_PRODUCT_MANAGEMENT_API
);
const productCompare = (a, b) => {
  const aProductName = ProductUtil.getLocalizedProductName(a);
  const bProductName = ProductUtil.getLocalizedProductName(b);
  return aProductName - bProductName;
};
// -- ACTIONS --
// eslint-disable-next-line consistent-return
export const fetchAllAvailabilityInformation = searchTerm => async dispatch => {
  try {
    // start
    dispatch({ type: Availability.AVAILABILITY_FETCH_STARTED });
    console.time("fetchAllAvailabilityInformation");
    const path = `${serviceUrl}availability/v1/?searchTerm=${searchTerm}`;
    const availabilityData = await RestService.get(path);
    const sortedAvailabilityInfo = [...availabilityData.availabilityInfo].sort(
      productCompare
    );
    console.timeEnd("fetchAllAvailabilityInformation");
    const payload = {
      availabilityInformation: sortedAvailabilityInfo,
      lastUpdate: availabilityData.lastUpdate,
    };

    // update
    dispatch({
      type: Availability.AVAILABILITY_FETCH_FINISHED,
      payload,
    });

    return payload;
  } catch (error) {
    dispatch({ type: Availability.AVAILABILITY_FETCH_ERROR, payload: error });
  }
};

export const fetchAvailabilityInformationByPharmaCompany =
  (profitCenterArray, searchTerm) =>
  // eslint-disable-next-line consistent-return
  async dispatch => {
    try {
      // start
      dispatch({ type: Availability.AVAILABILITY_FETCH_STARTED });

      console.time("fetchAvailabilityInformationByPharmaCompany");
      const profitCenters = profitCenterArray.join(",");
      const path = `${serviceUrl}availability/v1/pharma-company?profitCenters=${profitCenters}&searchTerm=${searchTerm}`;
      const availabilityData = await RestService.get(path);
      const sortedAvailabilityInfo = [
        ...availabilityData.availabilityInfo,
      ].sort(productCompare);
      console.timeEnd("fetchAvailabilityInformationByPharmaCompany");
      const payload = {
        availabilityInformation: sortedAvailabilityInfo,
        lastUpdate: availabilityData.lastUpdate,
      };

      // update
      dispatch({
        type: Availability.AVAILABILITY_FETCH_FINISHED,
        payload,
      });

      return payload;
    } catch (error) {
      dispatch({ type: Availability.AVAILABILITY_FETCH_ERROR, payload: error });
    }
  };
export const saveAvailabilityInformation =
  // eslint-disable-next-line consistent-return
  availabilityInformation => async dispatch => {
    try {
      // start
      dispatch({ type: Availability.AVAILABILITY_SEND_STARTED });

      const { productId } = availabilityInformation;

      console.time("saveAvailabilityInformation");
      const path = `${serviceUrl}availability/v1/${productId}`;
      const availabilityInfo = await RestService.post(
        path,
        availabilityInformation
      );
      console.timeEnd("saveAvailabilityInformation");

      availabilityInformation.latestUpdate = {
        ...availabilityInformation.latestUpdate,
        time: availabilityInfo.latestUpdate.time,
      };

      dispatch({
        type: Availability.AVAILABILITY_SEND_FINISHED,
        payload: availabilityInformation,
      });

      return availabilityInfo;
    } catch (error) {
      dispatch({ type: Availability.AVAILABILITY_SEND_ERROR, payload: error });
    }
  };

export const updateAvailabilityLocalChanges = (productId, status) => dispatch =>
  dispatch({
    type: Availability.UPDATE_AVAILABILITY_LOCAL_CHANGES,
    payload: { productId, status },
  });

export const exportAvailabilityToExcel =
  (searchTerm, sccId, locale) => async dispatch => {
    dispatch({
      type: Availability.EXPORT_TO_EXCEL_STARTED,
    });

    try {
      const path = `${serviceUrl}availability/v1/excelReport?searchTerm=${searchTerm}&locale=${locale}&sccId=${sccId}`;
      const reportLink = await RestService.get(path);
      dispatch({
        type: Availability.EXPORT_TO_EXCEL_FINISHED,
      });
      return reportLink;
    } catch (error) {
      dispatch({
        type: Availability.EXPORT_TO_EXCEL_ERROR,
        payload: error,
      });
      return Promise.reject(error);
    }
  };

// -- REDUCER --

const INIT_STATE = {
  availabilityInformation: [],
  lastDataUpdate: null,
  fetchingAvailabilityInformation: false,
  availabilityFetchError: null,
  sendingAvailabilityInformation: false,
  availabilitySendError: null,
  fetchingExcelReport: false,
};
// eslint-disable-next-line default-param-last
export const availabilityReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Availability.AVAILABILITY_FETCH_STARTED:
      return {
        ...state,
        fetchingAvailabilityInformation: true,
        availabilityFetchError: null,
      };
    case Availability.AVAILABILITY_FETCH_FINISHED:
      return {
        ...state,
        fetchingAvailabilityInformation: false,
        availabilityInformation: action.payload.availabilityInformation,
        lastDataUpdate: action.payload.lastUpdate,
      };
    case Availability.AVAILABILITY_FETCH_ERROR:
      return {
        ...state,
        fetchingAvailabilityInformation: false,
        availabilityFetchError: action.payload,
      };
    case Availability.AVAILABILITY_SEND_STARTED:
      return {
        ...state,
        sendingAvailabilityInformation: true,
        availabilitySendError: null,
      };
    case Availability.AVAILABILITY_SEND_ERROR:
      return {
        ...state,
        sendingAvailabilityInformation: false,
        availabilitySendError: action.payload,
      };
    case Availability.AVAILABILITY_SEND_FINISHED:
      return {
        ...state,
        sendingAvailabilityInformation: false,
        availabilityInformation: state.availabilityInformation.map(x => {
          if (x.productId === action.payload.productId) {
            return action.payload;
          }
          return x;
        }),
      };
    case Availability.UPDATE_AVAILABILITY_LOCAL_CHANGES: {
      const availabilityInformation = state.availabilityInformation.map(x => {
        if (x.productId === action.payload.productId) {
          return { ...x, localChanges: action.payload.status };
        }
        return x;
      });
      return { ...state, availabilityInformation };
    }
    case Availability.EXPORT_TO_EXCEL_STARTED:
      return { ...state, fetchingExcelReport: true };
    case Availability.EXPORT_TO_EXCEL_FINISHED:
    case Availability.EXPORT_TO_EXCEL_ERROR:
      return { ...state, fetchingExcelReport: false };
    default:
      return state;
  }
};
