/* eslint-disable import/prefer-default-export */

import {
  getDateWeekNumber,
  getDateYearNumber,
  getFormattedDate,
} from "../../utils/date/date";

export const renderEstimatedAvailability = (
  availability,
  showEstimationInWeeks,
  t
) => {
  const { information, hasPermanentInformation, availabilityDate } =
    availability;

  if (!availabilityDate || hasPermanentInformation) {
    return information ? "" : t("productManagementTable.availabilityUnknown");
  }

  return showEstimationInWeeks
    ? t("productManagementTable.week", {
        number: `${getDateWeekNumber(availabilityDate)}/${getDateYearNumber(availabilityDate)}`,
      })
    : getFormattedDate(availabilityDate);
};
