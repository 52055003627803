export default {
  // General
  transparent: "rgba(0,0,0,0)",
  white: "#FFFFFF",
  black: "#000000",

  // Primary
  primaryOrange: "#FFA000",

  // Secondary
  secondaryNavyBlue: "#2B3E5B",
  secondaryGray: "#A29791",
  secondaryDarkGray: "#757070",

  // Backgrounds
  backgroundLightOrange: "#FFCF8E",
  backgroundDarkGray: "#EAEEF5",
  backgroundGray: "#F5F4F3",
  backgroundGrayLight: "#F8F8F8",

  // Accessability & usability
  accessabilityGreen: "#388646",
  accessabilityRed: "#EC130E",
  highlightBlue: "#A8B9D6",
  errorRed: "#ec130e",
  hoverOrange: "#ffa00080",
  hoverGray: "#F1F0F0",
  underlineGray: "#e2e2e2",
};
