// For now we are using first customer id in array since we are not supporting customer selection
export const getCustomerId = customers => {
  if (Array.isArray(customers) && customers.length > 0) {
    return customers[0].customerId;
  }
  return null;
};

export const findCustomerById = (customerId, customers) => {
  if (customerId && Array.isArray(customers)) {
    return customers.find(customer => customer.customerId === customerId);
  }
  return null;
};
