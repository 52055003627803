// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { EcomUrls } from "../../../../utils/navigation/navigation";

const useStyles = makeStyles(() => ({
  placeholder: {
    height: "69px",
    width: "100%",
  },
}));

function OrigoHeader({ history }) {
  const classes = useStyles();
  const { tokenData } = useSelector(state => state.user);
  const [loaded, setLoaded] = useState(false);

  let divRef;
  // eslint-disable-next-line no-return-assign
  const setRef = ref => (divRef = ref);

  useEffect(() => {
    const unlisten = history.listen(() => {
      // force refresh if the path changes. (history.push is used)
      setLoaded(false);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  // Force refresh if token changes
  useEffect(() => {
    setLoaded(false);
  }, [tokenData]);

  useEffect(() => {
    if (!loaded) {
      console.log("-- Loading Origo header --");
      const script = document.createElement("script");
      script.src = process.env.REACT_APP_ORIGO_HEADER_URL;
      script.async = true;
      divRef.appendChild(script);
      script.onload = () => {
        console.log("-- Origo header loaded --");
        setLoaded(true);
      };
    }
  }, [tokenData, setLoaded, divRef, loaded]);

  const { Origo } = window;
  return (
    <div ref={setRef}>
      {loaded && (
        <Origo.Header
          accessToken={tokenData.accessToken}
          urls={{
            products: EcomUrls.Products,
            reports: EcomUrls.Reports,
            orders: EcomUrls.OrdersInvoices,
            cart: EcomUrls.ShoppingCart,
          }}
          onOrganizationChange={() => {
            console.log("on organization changed");
          }}
        />
      )}
      {!loaded && <div className={classes.placeholder} />}
    </div>
  );
}

OrigoHeader.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
  }).isRequired,
};

export default withRouter(OrigoHeader);
